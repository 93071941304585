var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useContext, useEffect } from 'react';
import { PdpContext } from '../store/pdp/PdpContext';
import { setDataLayer } from '../utils/dataLayer';
import { environmentConst } from '../config/environments/constants';
import { useCookies } from 'react-cookie';
import { useFulfillmentStore } from '../store/fulfillment/FulfillmentProvider';
import { checkInventoryForOnlineProduct } from '../utils/format/product';
var useGiftCardProdViewEvent = function (isGiftCardProduct, isNonBjsGiftCard) {
    var _productData$data2;
    var productData = useContext(PdpContext);
    var _a = __read(useFulfillmentStore(), 1), fulfillmentState = _a[0];
    var onlineFulfillmentData = fulfillmentState.fulfillmentOnline;
    var isInventoryAvailable = onlineFulfillmentData.isInventoryAvailable;
    var _b = __read(useCookies(), 1), cookies = _b[0];
    var userSignInResponse = cookies[environmentConst.USER_SIGNIN_RESPONSE_COOKIE];
    var personalizationID_live = cookies[environmentConst.PERSONALIZATIONID_LIVE];
    var isClubName = cookies[environmentConst.CLUB_NAME_COOKIE] || '';
    var uuID = cookies['uuID'];
    var checkInventoryForVariant = function (variant) {
        if (variant && (variant === null || variant === void 0 ? void 0 : variant.itemId) != 0) {
            var isInventoryAvailable_1 = checkInventoryForOnlineProduct(productData === null || productData === void 0 ? void 0 : productData.productData, variant === null || variant === void 0 ? void 0 : variant.itemId);
            return isInventoryAvailable_1;
        }
        return false;
    };
    function getEventName() {
        var _productData$data;
        var eventName = 'prodView';
        if (isGiftCardProduct && !isInventoryAvailable) {
            eventName = 'OOSprodView';
        }
        else if (isNonBjsGiftCard && !checkInventoryForVariant(productData === null || productData === void 0 || (_productData$data = productData.data) === null || _productData$data === void 0 ? void 0 : _productData$data.currentVariant)) {
            eventName = 'OOSprodView';
        }
        return eventName;
    }
    useEffect(function () {
        if (isGiftCardProduct || isNonBjsGiftCard) {
            var eventName = getEventName();
            setDataLayer(productData, userSignInResponse, personalizationID_live, isClubName, uuID, eventName);
        }
    }, [isGiftCardProduct, isNonBjsGiftCard, productData === null || productData === void 0 || (_productData$data2 = productData.data) === null || _productData$data2 === void 0 ? void 0 : _productData$data2.currentVariant]);
    return null;
};
export default useGiftCardProdViewEvent;
