var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export var getSelectedFulFillment = function (useStore) {
    var _state$fullFillmentDa;
    var _a = __read(useStore(), 2), state = _a[0], dispatch = _a[1];
    var fulFillmentType = state === null || state === void 0 || (_state$fullFillmentDa = state.fullFillmentData) === null || _state$fullFillmentDa === void 0 ? void 0 : _state$fullFillmentDa.fullFillmentType;
    return fulFillmentType;
};
