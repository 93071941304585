import { addMonetateCustomVariables } from './pdpUtil';
export var callKibo = function (partNumber, kiboArticleId, cookies, kiboClubId) {
    var isArray = Array.isArray(kiboArticleId);
    // @ts-ignore
    window.monetateQ = window.monetateQ || [];
    // @ts-ignore
    window.monetateQ.push(['setPageType', 'PRODUCT']);
    // @ts-ignore
    window.monetateQ.push(['addProductDetails', [{
                productId: partNumber.replace('P_', ''),
                sku: isArray ? kiboArticleId : kiboArticleId.replace('P_', '')
            }]]);
    addMonetateCustomVariables(cookies, kiboClubId);
    // @ts-ignore
    monetateQ.push(['trackData']);
};
