import uniqBy from 'lodash/uniqBy';
export var checkIsVariantProduct = function (productDetails) {
    if (productDetails !== null && productDetails !== void 0 && productDetails.definingAttributes && (productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.length) > 0) {
        return true;
    }
    return false;
};
export var getProductName = function (productDetails) {
    var productName = '';
    if (checkIsVariantProduct(productDetails)) {
        var _productDetails$descr;
        productName = productDetails === null || productDetails === void 0 || (_productDetails$descr = productDetails.description) === null || _productDetails$descr === void 0 ? void 0 : _productDetails$descr.name;
    }
    else {
        var _productDetails$entit;
        productName = productDetails === null || productDetails === void 0 || (_productDetails$entit = productDetails.entitledItems) === null || _productDetails$entit === void 0 || (_productDetails$entit = _productDetails$entit[0]) === null || _productDetails$entit === void 0 || (_productDetails$entit = _productDetails$entit.description) === null || _productDetails$entit === void 0 ? void 0 : _productDetails$entit.name;
    }
    return productName;
};
export var getItemPartNumberOrArticleId = function (productDetails) {
    var articleId = null;
    if (checkIsVariantProduct(productDetails)) {
        var defaultItemId_1 = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemPrices.defaultItemId;
        var defaultItem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (p) { return p.itemID === defaultItemId_1; });
        if (!!defaultItem) {
            articleId = defaultItem === null || defaultItem === void 0 ? void 0 : defaultItem.articleId;
        }
        else {
            var _productDetails$entit2;
            articleId = productDetails === null || productDetails === void 0 || (_productDetails$entit2 = productDetails.entitledItems) === null || _productDetails$entit2 === void 0 ? void 0 : _productDetails$entit2[0].articleId;
        }
    }
    else {
        var _productDetails$entit3;
        articleId = productDetails === null || productDetails === void 0 || (_productDetails$entit3 = productDetails.entitledItems) === null || _productDetails$entit3 === void 0 ? void 0 : _productDetails$entit3[0].articleId;
    }
    return articleId;
};
export var getVariantArticleId = function (productDetails, itemId) {
    var variantItem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (p) { return p.itemID === itemId; });
    if (variantItem) {
        return variantItem === null || variantItem === void 0 ? void 0 : variantItem.articleId;
    }
};
export var getProductModel = function (productDetails) {
    var _productDetails$entit4;
    return productDetails === null || productDetails === void 0 || (_productDetails$entit4 = productDetails.entitledItems) === null || _productDetails$entit4 === void 0 ? void 0 : _productDetails$entit4[0].manufacturerPartNumber;
};
export var getProductShopBrand = function (productDetails) {
    var _productDetails$hidde;
    var shopBrandAttr = productDetails === null || productDetails === void 0 || (_productDetails$hidde = productDetails.hiddenAttr) === null || _productDetails$hidde === void 0 ? void 0 : _productDetails$hidde.find(function (d) { return (d === null || d === void 0 ? void 0 : d.name) == 'ShopBrand'; });
    if (shopBrandAttr) {
        var brandName = productDetails === null || productDetails === void 0 ? void 0 : productDetails.manufacturerName;
        return {
            brandName: brandName,
            url: "/search?visualVariant.nonvisualVariant.mfname=" + brandName
        };
    }
    return null;
};
export var getProductAverageRating = function (productDetails) {
    var _productDetails$bjsra;
    return productDetails === null || productDetails === void 0 || (_productDetails$bjsra = productDetails.bjsrating) === null || _productDetails$bjsra === void 0 ? void 0 : _productDetails$bjsra.avgOvrlRating;
};
export var getProductTotalReviews = function (productDetails) {
    var _productDetails$bjsra2;
    return productDetails === null || productDetails === void 0 || (_productDetails$bjsra2 = productDetails.bjsrating) === null || _productDetails$bjsra2 === void 0 ? void 0 : _productDetails$bjsra2.fullReviews;
};
export var getColorVariantProduct = function (productDetails) {
    try {
        if (checkIsVariantProduct(productDetails)) {
            var colorVariantAttributes = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.find(function (d) {
                var _d$name;
                return (d === null || d === void 0 || (_d$name = d.name) === null || _d$name === void 0 ? void 0 : _d$name.toLowerCase()) === 'color';
            });
            if (colorVariantAttributes) {
                var colorVariants = colorVariantAttributes === null || colorVariantAttributes === void 0 ? void 0 : colorVariantAttributes.attributeValueDataBeans.map(function (variant) {
                    var _productDetails$itemA, _productDetails$itemA2;
                    if (variant.image1) {
                        variant.displayImage = variant.image1;
                    }
                    else {
                        var _entitledItem$descrip;
                        var entitledItem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (d) { return d.itemID === variant.itemId; });
                        if (entitledItem !== null && entitledItem !== void 0 && (_entitledItem$descrip = entitledItem.description) !== null && _entitledItem$descrip !== void 0 && _entitledItem$descrip.fullImage) {
                            var _entitledItem$descrip2;
                            variant.displayImage = entitledItem === null || entitledItem === void 0 || (_entitledItem$descrip2 = entitledItem.description) === null || _entitledItem$descrip2 === void 0 ? void 0 : _entitledItem$descrip2.fullImage;
                        }
                        else {
                            variant.displayImage = 'https://bjs.scene7.com/is/image/bjs/' + (entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber) + '?$bjs-Initial350$';
                        }
                    }
                    var inventory = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsItemsInventory.find(function (d) { return d.itemId === variant.itemId; });
                    if ((productDetails === null || productDetails === void 0 || (_productDetails$itemA = productDetails.itemAvailDetails[variant.itemId]) === null || _productDetails$itemA === void 0 ? void 0 : _productDetails$itemA.itemAvailableOnline) == 'Y' && (inventory === null || inventory === void 0 ? void 0 : inventory.availInventory) === true || (productDetails === null || productDetails === void 0 || (_productDetails$itemA2 = productDetails.itemAvailDetails[variant.itemId]) === null || _productDetails$itemA2 === void 0 ? void 0 : _productDetails$itemA2.itemAvailableInClub) == 'Y') {
                        variant.isInventoryAvailable = true;
                    }
                    else {
                        variant.isInventoryAvailable = false;
                    }
                    return variant;
                });
                return colorVariants;
            }
        }
    }
    catch (err) {
        console.log(err);
    }
    return null;
};
var getOtherVariantProduct = function (productDetails) {
    if (checkIsVariantProduct(productDetails)) {
        var _productDetails$defin;
        var variantProduct = productDetails === null || productDetails === void 0 || (_productDetails$defin = productDetails.definingAttributes) === null || _productDetails$defin === void 0 || (_productDetails$defin = _productDetails$defin[0]) === null || _productDetails$defin === void 0 ? void 0 : _productDetails$defin.name;
        if (variantProduct !== 'Color' && variantProduct !== 'DeliveryMethod' && variantProduct !== 'NonBJsDelMethodGC') {
            var _productDetails$defin2;
            var otherVariantAtrributes = productDetails === null || productDetails === void 0 || (_productDetails$defin2 = productDetails.definingAttributes) === null || _productDetails$defin2 === void 0 ? void 0 : _productDetails$defin2[0];
            var otherVariants = otherVariantAtrributes === null || otherVariantAtrributes === void 0 ? void 0 : otherVariantAtrributes.attributeValueDataBeans.map(function (variant) {
                var _productDetails$itemA3;
                var inventory = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsItemsInventory.find(function (d) { return d.itemId === variant.itemId; });
                if ((productDetails === null || productDetails === void 0 || (_productDetails$itemA3 = productDetails.itemAvailDetails[variant.itemId]) === null || _productDetails$itemA3 === void 0 ? void 0 : _productDetails$itemA3.itemAvailableOnline) == 'Y' && (inventory === null || inventory === void 0 ? void 0 : inventory.availInventory) === true) {
                    variant.isInventoryAvailable = true;
                }
                else {
                    variant.isInventoryAvailable = false;
                }
                return variant;
            }).filter(function (variant) { return variant.itemId !== '0'; });
            var otherVariantsFilt = otherVariants.filter(function (variant) {
                return productDetails.entitledItems.some(function (entItem) {
                    return entItem.itemID === variant.itemId;
                });
            });
            var finalVariantList = uniqBy(otherVariantsFilt, 'name');
            return {
                name: otherVariantAtrributes === null || otherVariantAtrributes === void 0 ? void 0 : otherVariantAtrributes.name,
                data: finalVariantList
            };
        }
    }
    return null;
};
var checkForProductVariant = function (product, filterKey) {
    var productAttributes = product === null || product === void 0 ? void 0 : product.definingAttributes;
    var isVariant = false;
    if (productAttributes && productAttributes.length > 0) {
        productAttributes.forEach(function (attribute) {
            if ((attribute === null || attribute === void 0 ? void 0 : attribute.name) == filterKey) {
                isVariant = true;
            }
        });
    }
    return isVariant;
};
export var checkInventoryForOnlineProduct = function (product, itemId) {
    var _product$otherData, _itemAvailability$ite;
    var itemAvailability = product === null || product === void 0 || (_product$otherData = product.otherData) === null || _product$otherData === void 0 ? void 0 : _product$otherData.itemAvailDetails;
    var isInventoryAvailable = false;
    if (itemAvailability && ((_itemAvailability$ite = itemAvailability[itemId]) === null || _itemAvailability$ite === void 0 ? void 0 : _itemAvailability$ite.itemAvailableOnline) == 'Y') {
        var _product$otherData2;
        product === null || product === void 0 || (_product$otherData2 = product.otherData) === null || _product$otherData2 === void 0 || (_product$otherData2 = _product$otherData2.bjsItemsInventory) === null || _product$otherData2 === void 0 || _product$otherData2.forEach(function (item) {
            if ((item === null || item === void 0 ? void 0 : item.itemId) == itemId) {
                isInventoryAvailable = item === null || item === void 0 ? void 0 : item.availInventory;
                return isInventoryAvailable;
            }
        });
    }
    return isInventoryAvailable;
};
var retrieveEntitleditem = function (itemId, productDetails) {
    var entitledItem = null;
    for (var i = 0; i < productDetails.entitledItems.length; i++) {
        if (productDetails.entitledItems[i].itemID === itemId) {
            entitledItem = productDetails.entitledItems[i];
            break;
        }
    }
    return entitledItem;
};
var sortVariants = function (giftCards) {
    if ((giftCards === null || giftCards === void 0 ? void 0 : giftCards.length) > 0) {
        return giftCards.sort(function (a, b) {
            if (a.price < b.price) {
                return -1;
            }
            if (a.price > b.price) {
                return 1;
            }
            return 0;
        });
    }
    return giftCards;
};
var retrievegiftCardVariants = function (productDetails) {
    var _productDetails$defin3;
    var allGiftCardVariants = null;
    if ((productDetails === null || productDetails === void 0 || (_productDetails$defin3 = productDetails.definingAttributes) === null || _productDetails$defin3 === void 0 ? void 0 : _productDetails$defin3.length) > 0) {
        for (var i = 0; i < (productDetails === null || productDetails === void 0 || (_productDetails$defin4 = productDetails.definingAttributes) === null || _productDetails$defin4 === void 0 ? void 0 : _productDetails$defin4.length); i++) {
            var _productDetails$defin4, _productDetails$defin5;
            if ((productDetails === null || productDetails === void 0 || (_productDetails$defin5 = productDetails.definingAttributes[i]) === null || _productDetails$defin5 === void 0 ? void 0 : _productDetails$defin5.name) === 'DeliveryMethod') {
                var _productDetails$defin6;
                allGiftCardVariants = {
                    digitalGiftCardVariants: [],
                    physicalGiftCardVariants: [],
                    digitalAndPhysicalGiftCardVariants: []
                };
                if (productDetails !== null && productDetails !== void 0 && (_productDetails$defin6 = productDetails.definingAttributes[i]) !== null && _productDetails$defin6 !== void 0 && _productDetails$defin6.attributeValueDataBeans) {
                    var _productDetails$defin7;
                    var data = productDetails === null || productDetails === void 0 || (_productDetails$defin7 = productDetails.definingAttributes[i]) === null || _productDetails$defin7 === void 0 ? void 0 : _productDetails$defin7.attributeValueDataBeans;
                    for (var j = 0; j < data.length; j++) {
                        var _data$j, _data$j2, _data$j3, _data$j4;
                        if (((_data$j = data[j]) === null || _data$j === void 0 ? void 0 : _data$j.name) === 'Digital' && ((_data$j2 = data[j]) === null || _data$j2 === void 0 ? void 0 : _data$j2.itemId) !== '0') {
                            if (productDetails.itemPrices) {
                                if (productDetails.itemPrices[data[j].itemId]) {
                                    var entitledItem = retrieveEntitleditem(data[j].itemId, productDetails);
                                    var partNumber = entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber;
                                    var giftCardVariantItemDetail = {
                                        itemId: data[j].itemId,
                                        price: productDetails.itemPrices[data[j].itemId].offerPrice,
                                        isDigital: true,
                                        isPhysical: false,
                                        priceWithCurrency: '$ ' + productDetails.itemPrices[data[j].itemId].offerPrice,
                                        entitleditem: entitledItem,
                                        partNumber: partNumber,
                                        giftCardRequest: null
                                    };
                                    allGiftCardVariants.digitalGiftCardVariants.push(giftCardVariantItemDetail);
                                    allGiftCardVariants.digitalAndPhysicalGiftCardVariants.push(giftCardVariantItemDetail);
                                }
                            }
                        }
                        else if (((_data$j3 = data[j]) === null || _data$j3 === void 0 ? void 0 : _data$j3.name) === 'Physical' && ((_data$j4 = data[j]) === null || _data$j4 === void 0 ? void 0 : _data$j4.itemId) !== '0') {
                            if (productDetails.itemPrices) {
                                if (productDetails.itemPrices[data[j].itemId]) {
                                    var entitledItem = retrieveEntitleditem(data[j].itemId, productDetails);
                                    var partNumber = entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber;
                                    var giftCardVariantItemDetail = {
                                        itemId: data[j].itemId,
                                        price: productDetails.itemPrices[data[j].itemId].offerPrice,
                                        isDigital: false,
                                        isPhysical: true,
                                        priceWithCurrency: '$ ' + productDetails.itemPrices[data[j].itemId].offerPrice,
                                        entitleditem: entitledItem,
                                        partNumber: partNumber,
                                        giftCardRequest: null
                                    };
                                    allGiftCardVariants.physicalGiftCardVariants.push(giftCardVariantItemDetail);
                                    allGiftCardVariants.digitalAndPhysicalGiftCardVariants.push(giftCardVariantItemDetail);
                                }
                            }
                        }
                    }
                }
                allGiftCardVariants.digitalGiftCardVariants = sortVariants(allGiftCardVariants.digitalGiftCardVariants);
                allGiftCardVariants.physicalGiftCardVariants = sortVariants(allGiftCardVariants.physicalGiftCardVariants);
                allGiftCardVariants.digitalAndPhysicalGiftCardVariants = sortVariants(allGiftCardVariants.digitalAndPhysicalGiftCardVariants);
                //filter out duplicates with same price from digitalAndPhysicalGiftcardvariants
                allGiftCardVariants.digitalAndPhysicalGiftCardVariants = allGiftCardVariants.digitalAndPhysicalGiftCardVariants.filter(function (attributes, index, self) { return index === self.findIndex(function (item) { return item.price === attributes.price; }); });
            }
        }
    }
    return allGiftCardVariants;
};
export var getBreadCrumb = function (productDetails) {
    var _productDetails$bread;
    return productDetails === null || productDetails === void 0 || (_productDetails$bread = productDetails.breadCrumbDetail) === null || _productDetails$bread === void 0 ? void 0 : _productDetails$bread[0];
};
export var getCatEntryId = function (productDetails) {
    var _productDetails$bjsit;
    return productDetails === null || productDetails === void 0 || (_productDetails$bjsit = productDetails.bjsitems) === null || _productDetails$bjsit === void 0 || (_productDetails$bjsit = _productDetails$bjsit[0]) === null || _productDetails$bjsit === void 0 ? void 0 : _productDetails$bjsit.itemId;
};
export var getProductImage = function (productDetails) {
    var _productDetails$produ;
    return productDetails === null || productDetails === void 0 || (_productDetails$produ = productDetails.productImages) === null || _productDetails$produ === void 0 ? void 0 : _productDetails$produ.fullImage;
};
export var getProductPartNumber = function (productDetails) {
    return productDetails === null || productDetails === void 0 ? void 0 : productDetails.partNumber;
};
export var getImmediateParentName = function (productDetails) {
    return productDetails === null || productDetails === void 0 ? void 0 : productDetails.immediateParentName;
};
export var getOrderType = function (productDetails) {
    var _productDetails$itemA4;
    if ((productDetails === null || productDetails === void 0 || (_productDetails$itemA4 = productDetails.itemAvailDetailsELIG) === null || _productDetails$itemA4 === void 0 || (_productDetails$itemA4 = _productDetails$itemA4.Eligibility) === null || _productDetails$itemA4 === void 0 ? void 0 : _productDetails$itemA4.length) > 0) {
        var _productDetails$itemA5;
        return productDetails !== null && productDetails !== void 0 && (_productDetails$itemA5 = productDetails.itemAvailDetailsELIG) !== null && _productDetails$itemA5 !== void 0 && (_productDetails$itemA5 = _productDetails$itemA5.Eligibility[0]) !== null && _productDetails$itemA5 !== void 0 && (_productDetails$itemA5 = _productDetails$itemA5.program) !== null && _productDetails$itemA5 !== void 0 && (_productDetails$itemA5 = _productDetails$itemA5.fresh) !== null && _productDetails$itemA5 !== void 0 && _productDetails$itemA5.Y ? 'Bopic Fresh' : undefined;
    }
    return;
};
export var getVariant = function (productDetails) {
    var _productDetails$defin8;
    return productDetails === null || productDetails === void 0 || (_productDetails$defin8 = productDetails.definingAttributes) === null || _productDetails$defin8 === void 0 || (_productDetails$defin8 = _productDetails$defin8[0]) === null || _productDetails$defin8 === void 0 ? void 0 : _productDetails$defin8.attributeValueDataBeans;
};
export var getProductCatentryId = function (productDetails) {
    if (productDetails !== null && productDetails !== void 0 && productDetails.entitledItems && Array.isArray(productDetails.entitledItems)) {
        var _productDetails$entit5;
        return productDetails === null || productDetails === void 0 || (_productDetails$entit5 = productDetails.entitledItems) === null || _productDetails$entit5 === void 0 || (_productDetails$entit5 = _productDetails$entit5[0]) === null || _productDetails$entit5 === void 0 || (_productDetails$entit5 = _productDetails$entit5.description) === null || _productDetails$entit5 === void 0 ? void 0 : _productDetails$entit5.itemId;
    }
};
export var getPrice = function (productDetails) {
    if (productDetails !== null && productDetails !== void 0 && productDetails.entitledItems && Array.isArray(productDetails.entitledItems)) {
        var _productDetails$entit6;
        return productDetails === null || productDetails === void 0 || (_productDetails$entit6 = productDetails.entitledItems) === null || _productDetails$entit6 === void 0 || (_productDetails$entit6 = _productDetails$entit6[0]) === null || _productDetails$entit6 === void 0 || (_productDetails$entit6 = _productDetails$entit6.calculatedContractPrice) === null || _productDetails$entit6 === void 0 ? void 0 : _productDetails$entit6.amount;
    }
};
export var getItemDescription = function (productDetails) {
    if (productDetails !== null && productDetails !== void 0 && productDetails.entitledItems && Array.isArray(productDetails.entitledItems)) {
        var _productDetails$entit7;
        return productDetails === null || productDetails === void 0 || (_productDetails$entit7 = productDetails.entitledItems) === null || _productDetails$entit7 === void 0 || (_productDetails$entit7 = _productDetails$entit7[0]) === null || _productDetails$entit7 === void 0 || (_productDetails$entit7 = _productDetails$entit7.description) === null || _productDetails$entit7 === void 0 ? void 0 : _productDetails$entit7.longDescription;
    }
};
var checkIfProductIsHeavyItem = function (productDetails) {
    var _productDetails$hidde2;
    var isProductHeavy = false;
    productDetails === null || productDetails === void 0 || (_productDetails$hidde2 = productDetails.hiddenAttr) === null || _productDetails$hidde2 === void 0 || _productDetails$hidde2.forEach(function (item) {
        if ((item === null || item === void 0 ? void 0 : item.name) === 'heavyItem') {
            var _item$attributeValueD;
            var value = item === null || item === void 0 || (_item$attributeValueD = item.attributeValueDataBeans) === null || _item$attributeValueD === void 0 || (_item$attributeValueD = _item$attributeValueD[0]) === null || _item$attributeValueD === void 0 || (_item$attributeValueD = _item$attributeValueD.value) === null || _item$attributeValueD === void 0 || (_item$attributeValueD = _item$attributeValueD.toLowerCase()) === null || _item$attributeValueD === void 0 ? void 0 : _item$attributeValueD.trim();
            if (value == 'true') {
                isProductHeavy = true;
            }
        }
    });
    return isProductHeavy;
};
export var formattedProductData = function (productData) {
    var productDetails = productData === null || productData === void 0 ? void 0 : productData.productDetailsData;
    var product = {
        productName: getProductName(productDetails),
        articleId: getItemPartNumberOrArticleId(productDetails),
        description: getItemDescription(productDetails),
        itemPartNumber: getItemPartNumberOrArticleId(productDetails),
        model: getProductModel(productDetails),
        manufacturerPartNumber: getProductModel(productDetails),
        shopBrand: getProductShopBrand(productDetails),
        averageRating: getProductAverageRating(productDetails),
        totalReviews: getProductTotalReviews(productDetails),
        colorVariants: getColorVariantProduct(productDetails),
        otherVariants: getOtherVariantProduct(productDetails),
        isVariant: checkIsVariantProduct(productDetails),
        isGiftCardVariant: checkForProductVariant(productDetails, 'DeliveryMethod'),
        isNonBjsGiftCard: checkForProductVariant(productDetails, 'NonBJsDelMethodGC'),
        giftCardvariants: retrievegiftCardVariants(productDetails),
        breadCrumbDetail: getBreadCrumb(productDetails),
        catEntryId: getCatEntryId(productDetails),
        productImage: getProductImage(productDetails),
        partNumber: getProductPartNumber(productDetails),
        immediateParentName: getImmediateParentName(productDetails),
        orderType: getOrderType(productDetails),
        variant: getVariant(productDetails),
        productCatentryId: getProductCatentryId(productDetails),
        price: getPrice(productDetails),
        otherData: productDetails,
        isProductHeavyItem: checkIfProductIsHeavyItem(productDetails)
    };
    return product;
};
