import { Button } from 'react-bootstrap';
import styled from 'styled-components';
export var SharedStyledButton = styled(Button).withConfig({
    displayName: "SharedStyledButton",
    componentId: "sc-qvkw4f-0"
})(["font-family:Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;background-color:", ";border:", ";color:", ";height:40px;font-weight:700;font-size:14px;letter-spacing:0.5px;line-height:16px;border-radius:5px;text-transform:uppercase;padding:0;&:disabled{color:#555;border:1px solid #ddd !important;background:#ddd;&:hover,&:focus,&:active{color:#555 !important;border:1px solid #ddd !important;background:#ddd !important;}}&:hover{background-color:", ";color:", ";}&:focus,&:active{background-color:", " !important;box-shadow:none !important;color:", " !important;}"], function (props) {
    switch (props.variant) {
        case 'primary':
            return '#c03';
        case 'outline-secondary':
            return 'white';
        case 'dark':
            return '#555';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'none';
        case 'outline-secondary':
            return '1px solid #555';
        case 'dark':
            return 'none';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'white';
        case 'outline-secondary':
            return '#212529';
        case 'dark':
            return 'white';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return '#a30028';
        case 'outline-secondary':
            return '#ddd';
        case 'dark':
            return '#303030';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'white';
        case 'outline-secondary':
            return '#212529';
        case 'dark':
            return 'white';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return '#a30028';
        case 'outline-secondary':
            return '#ddd';
        case 'dark':
            return '#303030';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'white';
        case 'outline-secondary':
            return '#212529';
        case 'dark':
            return 'white';
    }
});
