var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var SET_ELIGIBILITY = 'SET_ELIGIBILITY';
export var SET_ONLINE_ZIPCODE = 'SET_ONLINE_ZIPCODE';
export var SET_BOPIC_ZIPCODE = 'SET_BOPIC_ZIPCODE';
export var SET_SDD_ZIPCODE = 'SET_SDD_ZIPCODE';
export var SET_BOPIC25_MILES = 'SET_BOPIC25_MILES';
export var SET_BOPIC_PRODUCT_LOCATIONS = 'SET_BOPIC_PRODUCT_LOCATIONS';
export var DISABLE_INVENTORY = 'DISABLE_INVENTORY';
export var initialState = {
    fulfillmentOnline: {
        isEligible: false,
        displayItem: false,
        disabled: false,
        isFreeShipping: false,
        shippingText: 'Shipping',
        isFlatRate: false,
        estimatedDelivery: null,
        onlineZipCode: null,
        isInventoryAvailable: false,
        isLoaded: false
    },
    fulfillmentSdd: {
        isEligible: false,
        displayItem: false,
        disabled: false,
        sddZipCode: null,
        isInventoryAvailable: false,
        isLoaded: false
    },
    fulfillmentBopic: {
        isEligible: false,
        displayItem: false,
        disabled: false,
        bopicZipCode: null,
        isInventoryAvailable: false,
        isWithIn25MilesAvailable: false,
        isLoaded: false,
        productLocation: {}
    }
};
export var setEligibility = function (isEligible, eligibilityType, displayItem, disabled, additionalData) {
    if (additionalData === void 0) { additionalData = {}; }
    return ({
        type: SET_ELIGIBILITY,
        data: {
            isEligible: isEligible,
            eligibilityType: eligibilityType,
            displayItem: displayItem,
            disabled: disabled,
            additionalData: additionalData
        }
    });
};
export var setOnlineZipCode = function (zipCode) { return ({
    type: SET_ONLINE_ZIPCODE,
    data: zipCode
}); };
export var setBopicZipCode = function (zipCode) { return ({
    type: SET_BOPIC_ZIPCODE,
    data: zipCode
}); };
export var setSddZipCode = function (zipCode) { return ({
    type: SET_SDD_ZIPCODE,
    data: zipCode
}); };
export var setBopic25Miles = function (isAvailable) { return ({
    type: SET_BOPIC25_MILES,
    data: isAvailable
}); };
export var setBopicProductLocations = function (data) { return ({
    type: SET_BOPIC_PRODUCT_LOCATIONS,
    data: data
}); };
export var setDisableInventory = function (eligibilityType) { return ({
    type: DISABLE_INVENTORY,
    data: {
        eligibilityType: eligibilityType
    }
}); };
export var FulfillmentReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action.type === SET_ELIGIBILITY) {
        var _a = action === null || action === void 0 ? void 0 : action.data, isEligible = _a.isEligible, eligibilityType = _a.eligibilityType, displayItem = _a.displayItem, disabled = _a.disabled, additionalData = _a.additionalData;
        if (eligibilityType === 'bopic') {
            return __assign(__assign({}, state), { fulfillmentBopic: __assign(__assign(__assign({}, state.fulfillmentBopic), { isEligible: isEligible, displayItem: displayItem, disabled: disabled }), additionalData) });
        }
        if (eligibilityType === 'sdd') {
            return __assign(__assign({}, state), { fulfillmentSdd: __assign(__assign(__assign({}, state.fulfillmentSdd), { isEligible: isEligible, displayItem: displayItem, disabled: disabled }), additionalData) });
        }
        if (eligibilityType === 'online') {
            return __assign(__assign({}, state), { fulfillmentOnline: __assign(__assign(__assign({}, state.fulfillmentOnline), { isEligible: isEligible, displayItem: displayItem, disabled: disabled }), additionalData) });
        }
    }
    if (action.type === DISABLE_INVENTORY) {
        var eligibilityType = (action === null || action === void 0 ? void 0 : action.data).eligibilityType;
        if (eligibilityType === 'online') {
            return __assign(__assign({}, state), { fulfillmentOnline: __assign(__assign({}, state.fulfillmentOnline), { isInventoryAvailable: false, disabled: true }) });
        }
    }
    if (action.type === SET_ONLINE_ZIPCODE) {
        return __assign(__assign({}, state), { fulfillmentOnline: __assign(__assign({}, state.fulfillmentOnline), { onlineZipCode: action.data }) });
    }
    if (action.type === SET_BOPIC_ZIPCODE) {
        return __assign(__assign({}, state), { fulfillmentBopic: __assign(__assign({}, state.fulfillmentBopic), { bopicZipCode: action.data }) });
    }
    if (action.type === SET_SDD_ZIPCODE) {
        return __assign(__assign({}, state), { fulfillmentSdd: __assign(__assign({}, state.fulfillmentSdd), { sddZipCode: action.data }) });
    }
    if (action.type === SET_BOPIC25_MILES) {
        return __assign(__assign({}, state), { fulfillmentBopic: __assign(__assign({}, state.fulfillmentBopic), { isWithIn25MilesAvailable: action.data }) });
    }
    if (action.type === SET_BOPIC_PRODUCT_LOCATIONS) {
        return __assign(__assign({}, state), { fulfillmentBopic: __assign(__assign({}, state.fulfillmentBopic), { productLocation: action.data }) });
    }
    return state;
};
