export var checkFeatureFlag = function (segmentName, featureSegments) {
    if (featureSegments != null && featureSegments != undefined) {
        var _featureSegments$toSt;
        if (featureSegments !== null && featureSegments !== void 0 && (_featureSegments$toSt = featureSegments.toString()) !== null && _featureSegments$toSt !== void 0 && _featureSegments$toSt.includes(segmentName)) {
            return true;
        }
    }
    return false;
};
// check for both bjs and non bjs gift card on pdp
export var PDPHasGCItem = function (productDetails) {
    var merchatId = productDetails === null || productDetails === void 0 ? void 0 : productDetails.merchandiseCatagoryIdentifier;
    var GCMerchCatID = ['402030190', '402030242', '402030191', 'B01010239'];
    if (merchatId != null || merchatId != undefined || merchatId != '') {
        if (GCMerchCatID !== null && GCMerchCatID !== void 0 && GCMerchCatID.includes(merchatId === null || merchatId === void 0 ? void 0 : merchatId.toString())) {
            return true;
        }
    }
    return false;
};
export var cobrandSavingsAmount = function (productDetails, userSignInResponse) {
    var amount = 0;
    var savings = '5%';
    var cardImage = ' https://bjs.scene7.com/is/image/bjs/2022_BJs_OnePlusCard';
    //Product amount
    if (productDetails !== null && productDetails !== void 0 && productDetails.entitledItems && Array.isArray(productDetails.entitledItems)) {
        var _productDetails$entit;
        amount = productDetails === null || productDetails === void 0 || (_productDetails$entit = productDetails.entitledItems[0]) === null || _productDetails$entit === void 0 || (_productDetails$entit = _productDetails$entit.calculatedContractPrice) === null || _productDetails$entit === void 0 ? void 0 : _productDetails$entit.amount;
    }
    if (amount > 500) {
        if ((userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.cobrandProductId) === 'BJSONE') {
            var _ref;
            savings = '$' + ((_ref = amount * 0.03) === null || _ref === void 0 || (_ref = _ref.toFixed(0)) === null || _ref === void 0 ? void 0 : _ref.toString());
            cardImage = 'https://bjs.scene7.com/is/image/bjs/One-Card?fmt=png-alpha';
        }
        else {
            var _ref2;
            savings = '$' + ((_ref2 = amount * 0.05) === null || _ref2 === void 0 || (_ref2 = _ref2.toFixed(0)) === null || _ref2 === void 0 ? void 0 : _ref2.toString());
        }
    }
    else {
        if ((userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.cobrandProductId) === 'BJSONE') {
            savings = '3%';
            cardImage = 'https://bjs.scene7.com/is/image/bjs/One-Card?fmt=png-alpha';
        }
        else {
            savings = '5%';
        }
    }
    return {
        savings: savings,
        cardImage: cardImage
    };
};
export var isLoggedIn = function (userSignInResponse) {
    if (userSignInResponse != '' && userSignInResponse != null && userSignInResponse != undefined && (userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.ableToCobrand) === 'Y') {
        return true;
    }
    return false;
};
