var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var SET_FULLFILLMENT_DATA = 'SET_FULLFILLMENT_DATA';
export var SET_PRICE_DATA = 'SET_PRICE_DATA';
export var SET_TOTAL_PRICE_QUANTITY = 'SET_TOTAL_PRICE_QUANTITY';
export var IS_WARRANTY_PRODUCT = 'IS_WARRANTY_PRODUCT';
export var WARRANTY_TO_CART = 'WARRANTY_TO_CART';
export var SET_FREQUENCY = 'SET_FREQUENCY';
export var SET_MINICART_DATA = 'SET_MINICART_DATA';
export var SET_TOTAL_QUANTITY_ORDER = 'SET_TOTAL_QUANTITY_ORDER';
export var SET_VARIANT_OPTIONS_NOT_SELECTED = 'SET_VARIANT_OPTIONS_NOT_SELECTED';
export var SET_SDD_ADDRESS_NOT_SELECTED = 'SET_SDD_ADDRESS_NOT_SELECTED';
export var SET_MAX_QUANTITY_ERROR = 'SET_MAX_QUANTITY_ERROR';
export var SET_ITEM_MAX_QUANTITY = 'SET_ITEM_MAX_QUANTITY';
export var SET_QUANTITY_CART = 'SET_QUANTITY_CART';
export var SET_CART_MAX_ITEM = 'SET_CART_MAX_ITEM';
export var SET_SDD_FROM_ADD_CART = 'SET_SDD_FROM_ADD_CART';
export var SET_NO_WARRANTY = 'SET_NO_WARRANTY';
export var IS_VIEW_CART = 'IS_VIEW_CART';
export var initialState = {
    fullFillmentData: {
        fullFillmentType: '',
        fulfillmentFrequency: null,
        fulfillmentFrequencyUOM: null
    },
    isWarrantyProduct: false,
    warrantyToCart: false,
    priceData: {
        actualPrice: 0,
        toShowInstant: false,
        instantPercentage: {
            isInstantPercentageAvailable: false,
            instantSavingsPercentage: 0,
            instantSavingsPrice: 0
        },
        weightedRange: {
            isWeighted: false,
            minPrice: 0,
            maxPrice: 0
        },
        isMapPrice: false,
        mapTypeMessage: '',
        offerExpiry: '',
        isLoading: true
    },
    totalPriceQuantity: {
        totalPrice: 0,
        totalQuantity: 0
    },
    miniCartData: {},
    maxQtyReachedError: false,
    isVariantOptionsNotSelected: false,
    isSddAddressNotSelected: false,
    qtyInCart: 0,
    itemMaxQuantity: 0,
    cartMaxItem: 0,
    noWarranty: false,
    isViewCart: false,
    isSddFromAddCart: false
};
export var setFullFillmentData = function (data) { return ({
    type: SET_FULLFILLMENT_DATA,
    data: data
}); };
export var setCartMaxItem = function (data) { return ({
    type: SET_CART_MAX_ITEM,
    data: data
}); };
export var setPriceData = function (data) { return ({
    type: SET_PRICE_DATA,
    data: data
}); };
export var setTotalPriceQuantity = function (item) { return ({
    type: SET_TOTAL_PRICE_QUANTITY,
    item: item
}); };
export var setWarrantyProduct = function (bool) { return ({
    type: IS_WARRANTY_PRODUCT,
    bool: bool
}); };
export var setViewCart = function (bool) { return ({
    type: IS_VIEW_CART,
    bool: bool
}); };
export var setWarrantyToCart = function (bool) { return ({
    type: WARRANTY_TO_CART,
    bool: bool
}); };
export var setFrequency = function (_a) {
    var fulfillmentFrequency = _a.fulfillmentFrequency, fulfillmentFrequencyUOM = _a.fulfillmentFrequencyUOM;
    return ({
        type: SET_FREQUENCY,
        data: {
            fulfillmentFrequency: fulfillmentFrequency,
            fulfillmentFrequencyUOM: fulfillmentFrequencyUOM
        }
    });
};
export var setTotalQuantityInOrder = function (item) { return ({
    type: SET_TOTAL_QUANTITY_ORDER,
    item: item
}); };
export var setVariantOptionsNotSelected = function (value) { return ({
    type: SET_VARIANT_OPTIONS_NOT_SELECTED,
    data: value
}); };
export var setSddAddressNotSelected = function (value) { return ({
    type: SET_SDD_ADDRESS_NOT_SELECTED,
    data: value
}); };
export var setSddFromAddCart = function (value) { return ({
    type: SET_SDD_FROM_ADD_CART,
    data: value
}); };
export var PriceReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    if (action.type === SET_PRICE_DATA) {
        return __assign(__assign({}, state), { priceData: action.data });
    }
    if (action.type === SET_FULLFILLMENT_DATA) {
        return __assign(__assign({}, state), { fullFillmentData: __assign(__assign({}, state.fullFillmentData), action.data) });
    }
    if (action.type === SET_CART_MAX_ITEM) {
        return __assign(__assign({}, state), { cartMaxItem: action.data });
    }
    if (action.type === SET_TOTAL_PRICE_QUANTITY) {
        return __assign(__assign({}, state), { totalPriceQuantity: action.item });
    }
    if (action.type === IS_WARRANTY_PRODUCT) {
        return __assign(__assign({}, state), { isWarrantyProduct: action.bool });
    }
    if (action.type === WARRANTY_TO_CART) {
        return __assign(__assign({}, state), { warrantyToCart: action.bool });
    }
    if (action.type === IS_VIEW_CART) {
        return __assign(__assign({}, state), { isViewCart: action.bool });
    }
    if (action.type === SET_NO_WARRANTY) {
        return __assign(__assign({}, state), { noWarranty: action.bool });
    }
    if (action.type === SET_FREQUENCY) {
        var _action$data, _action$data2;
        return __assign(__assign({}, state), { fullFillmentData: __assign(__assign({}, state.fullFillmentData), { fulfillmentFrequency: (_action$data = action.data) === null || _action$data === void 0 ? void 0 : _action$data.fulfillmentFrequency, fulfillmentFrequencyUOM: (_action$data2 = action.data) === null || _action$data2 === void 0 ? void 0 : _action$data2.fulfillmentFrequencyUOM }) });
    }
    if (action.type === SET_MINICART_DATA) {
        return __assign(__assign({}, state), { miniCartData: action.data });
    }
    if (action.type === SET_TOTAL_QUANTITY_ORDER) {
        return __assign(__assign({}, state), { totalQuantityInOrder: action.item });
    }
    if (action.type === SET_VARIANT_OPTIONS_NOT_SELECTED) {
        return __assign(__assign({}, state), { isVariantOptionsNotSelected: action.data });
    }
    if (action.type === SET_SDD_ADDRESS_NOT_SELECTED) {
        return __assign(__assign({}, state), { isSddAddressNotSelected: action.data });
    }
    if (action.type === SET_SDD_FROM_ADD_CART) {
        return __assign(__assign({}, state), { isSddFromAddCart: action.data });
    }
    if (action.type === SET_MAX_QUANTITY_ERROR) {
        return __assign(__assign({}, state), { maxQtyReachedError: action.data });
    }
    if (action.type === SET_QUANTITY_CART) {
        return __assign(__assign({}, state), { qtyInCart: action.data });
    }
    if (action.type === SET_ITEM_MAX_QUANTITY) {
        return __assign(__assign({}, state), { itemMaxQuantity: action.data });
    }
};
export var setMiniCartData = function (data) { return ({
    type: SET_MINICART_DATA,
    data: data
}); };
export var setMaxQtyReachedError = function (data) { return ({
    type: SET_MAX_QUANTITY_ERROR,
    data: data
}); };
export var setItemMaxQuantity = function (data) { return ({
    type: SET_ITEM_MAX_QUANTITY,
    data: data
}); };
export var setQtyInCart = function (data) { return ({
    type: SET_QUANTITY_CART,
    data: data
}); };
export var setNoWarranty = function (bool) { return ({
    type: SET_NO_WARRANTY,
    bool: bool
}); };
