import { environmentConst } from '../config/environments/constants';
import { parseCookie } from './helper';
export var getkillSwitchBNPL = function (conf) {
    var _conf$storeConf;
    var killSwitchBNPL = conf === null || conf === void 0 || (_conf$storeConf = conf.storeConf) === null || _conf$storeConf === void 0 || (_conf$storeConf = _conf$storeConf.resultList) === null || _conf$storeConf === void 0 || (_conf$storeConf = _conf$storeConf[0]) === null || _conf$storeConf === void 0 || (_conf$storeConf = _conf$storeConf.userData) === null || _conf$storeConf === void 0 ? void 0 : _conf$storeConf.killSwitchBNPL;
    return killSwitchBNPL;
};
export var getKillSwitchSubscription = function (conf) {
    var _conf$storeConf2;
    var killSwitchSubscription = conf === null || conf === void 0 || (_conf$storeConf2 = conf.storeConf) === null || _conf$storeConf2 === void 0 || (_conf$storeConf2 = _conf$storeConf2.resultList) === null || _conf$storeConf2 === void 0 || (_conf$storeConf2 = _conf$storeConf2[0]) === null || _conf$storeConf2 === void 0 || (_conf$storeConf2 = _conf$storeConf2.userData) === null || _conf$storeConf2 === void 0 ? void 0 : _conf$storeConf2.killSwitchSubscription;
    return killSwitchSubscription;
};
export var getKillSwitchBOPIC = function (conf) {
    var _conf$storeConf3;
    var killSwitchBOPIC = conf === null || conf === void 0 || (_conf$storeConf3 = conf.storeConf) === null || _conf$storeConf3 === void 0 || (_conf$storeConf3 = _conf$storeConf3.resultList) === null || _conf$storeConf3 === void 0 || (_conf$storeConf3 = _conf$storeConf3[0]) === null || _conf$storeConf3 === void 0 || (_conf$storeConf3 = _conf$storeConf3.userData) === null || _conf$storeConf3 === void 0 ? void 0 : _conf$storeConf3.killSwitchBOPIC;
    return killSwitchBOPIC;
};
export var getKillSwitchSDD = function (conf) {
    var _conf$storeConf4;
    var killSwitchSDD = conf === null || conf === void 0 || (_conf$storeConf4 = conf.storeConf) === null || _conf$storeConf4 === void 0 || (_conf$storeConf4 = _conf$storeConf4.resultList) === null || _conf$storeConf4 === void 0 || (_conf$storeConf4 = _conf$storeConf4[0]) === null || _conf$storeConf4 === void 0 || (_conf$storeConf4 = _conf$storeConf4.userData) === null || _conf$storeConf4 === void 0 ? void 0 : _conf$storeConf4.killSwitchSDD;
    return killSwitchSDD;
};
export var getKillSwitchMaxWeightedItem = function (conf) {
    var _conf$storeConf5;
    var maxWeightedItem = conf === null || conf === void 0 || (_conf$storeConf5 = conf.storeConf) === null || _conf$storeConf5 === void 0 || (_conf$storeConf5 = _conf$storeConf5.resultList) === null || _conf$storeConf5 === void 0 || (_conf$storeConf5 = _conf$storeConf5[0]) === null || _conf$storeConf5 === void 0 || (_conf$storeConf5 = _conf$storeConf5.userData) === null || _conf$storeConf5 === void 0 ? void 0 : _conf$storeConf5.MAX_WEIGHTED_AMOUNT;
    return maxWeightedItem;
};
export var getKillSwitchSponsoredBanner = function (conf) {
    var _conf$storeConf6, _conf$storeConf7;
    return (conf === null || conf === void 0 || (_conf$storeConf6 = conf.storeConf) === null || _conf$storeConf6 === void 0 || (_conf$storeConf6 = _conf$storeConf6.resultList) === null || _conf$storeConf6 === void 0 || (_conf$storeConf6 = _conf$storeConf6[0]) === null || _conf$storeConf6 === void 0 || (_conf$storeConf6 = _conf$storeConf6.userData) === null || _conf$storeConf6 === void 0 ? void 0 : _conf$storeConf6.promoteIQWebPdpBanner) === 'OFF' && ((_conf$storeConf7 = conf.storeConf) === null || _conf$storeConf7 === void 0 || (_conf$storeConf7 = _conf$storeConf7.resultList) === null || _conf$storeConf7 === void 0 || (_conf$storeConf7 = _conf$storeConf7[0]) === null || _conf$storeConf7 === void 0 || (_conf$storeConf7 = _conf$storeConf7.userData) === null || _conf$storeConf7 === void 0 ? void 0 : _conf$storeConf7.killSwitchWebRmp) === 'OFF';
};
export var getKillSwitchForBopicProductLocations = function (conf) {
    var _conf$storeConf8;
    var memberLocation = (conf === null || conf === void 0 || (_conf$storeConf8 = conf.storeConf) === null || _conf$storeConf8 === void 0 || (_conf$storeConf8 = _conf$storeConf8.resultList) === null || _conf$storeConf8 === void 0 || (_conf$storeConf8 = _conf$storeConf8[0]) === null || _conf$storeConf8 === void 0 || (_conf$storeConf8 = _conf$storeConf8.userData) === null || _conf$storeConf8 === void 0 ? void 0 : _conf$storeConf8.killSwitchProdlocSegCheck) === 'OFF';
    return memberLocation;
};
export var getProductLocationList = function (conf) {
    var _conf$storeConf9, _productLocationDetai, _productLocationDetai2;
    var productLocationDetails = conf === null || conf === void 0 || (_conf$storeConf9 = conf.storeConf) === null || _conf$storeConf9 === void 0 || (_conf$storeConf9 = _conf$storeConf9.resultList) === null || _conf$storeConf9 === void 0 || (_conf$storeConf9 = _conf$storeConf9[0]) === null || _conf$storeConf9 === void 0 || (_conf$storeConf9 = _conf$storeConf9.userData) === null || _conf$storeConf9 === void 0 ? void 0 : _conf$storeConf9.productlocdisplayclublist;
    if (productLocationDetails !== null && productLocationDetails !== void 0 && (_productLocationDetai = productLocationDetails.toString()) !== null && _productLocationDetai !== void 0 && _productLocationDetai.includes(parseCookie(environmentConst.WC_PICKUP_STORE)) || productLocationDetails !== null && productLocationDetails !== void 0 && (_productLocationDetai2 = productLocationDetails.toString()) !== null && _productLocationDetai2 !== void 0 && _productLocationDetai2.includes('all')) {
        return true;
    }
    else {
        return false;
    }
};
export var getKillSwitchFbt = function (conf) {
    var _conf$storeConf10, _conf$storeConf11;
    return (conf === null || conf === void 0 || (_conf$storeConf10 = conf.storeConf) === null || _conf$storeConf10 === void 0 || (_conf$storeConf10 = _conf$storeConf10.resultList) === null || _conf$storeConf10 === void 0 || (_conf$storeConf10 = _conf$storeConf10[0]) === null || _conf$storeConf10 === void 0 || (_conf$storeConf10 = _conf$storeConf10.userData) === null || _conf$storeConf10 === void 0 ? void 0 : _conf$storeConf10.PromoteIQWebRecomendationCarousel) === 'OFF' && ((_conf$storeConf11 = conf.storeConf) === null || _conf$storeConf11 === void 0 || (_conf$storeConf11 = _conf$storeConf11.resultList) === null || _conf$storeConf11 === void 0 || (_conf$storeConf11 = _conf$storeConf11[0]) === null || _conf$storeConf11 === void 0 || (_conf$storeConf11 = _conf$storeConf11.userData) === null || _conf$storeConf11 === void 0 ? void 0 : _conf$storeConf11.killSwitchWebRmp) === 'OFF';
};
