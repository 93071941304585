import axios from 'axios';
import { environmentConst as environment } from '../config/environments/constants';
import { setupCache } from '../utils/axios-cache-adapter';
// Create `axios-cache-adapter` instance
var cache = setupCache({
    maxAge: 0
});
var regionValue = process.env.region;
var isServerSideCall = !(typeof window != 'undefined' && window.document);
export default axios.create({
    baseURL: environment.API_BASE_URL,
    headers: isServerSideCall && regionValue ? {
        'bjs-region': regionValue
    } : {}
});
export var axiosCache = axios.create({
    baseURL: environment.API_BASE_URL,
    adapter: cache.adapter,
    headers: isServerSideCall && regionValue ? {
        'bjs-region': regionValue
    } : {}
});
